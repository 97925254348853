<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Kelas
              </span>
            </h3>
            <div class="card-toolbar">
              <router-link
                :to="`/kelas/create`"
                href="#"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Tambah Kelas Baru</router-link
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :filter="filter"
              @filtered="onFiltered"
              :items="currentClassList.data"
              :fields="fields"
              :busy="isLoading"
              show-empty
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              "
              responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada Kelas ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada Kelas ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>

              <template #cell(action)="data">
                <b-dropdown
                  variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <router-link
                        :to="`/kelas/${data.item._id}/edit`"
                        class="navi-link"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-pen"></i>
                        </span>
                        <span class="navi-text">Ubah Kelas</span>
                      </router-link>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <span
                        class="navi-link cursor-pointer"
                        @click="deleteItem(data.item)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-trash"></i>
                        </span>
                        <span class="navi-text">Hapus Kelas</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
                &nbsp;
                <router-link
                  :to="`/kelas/${data.item._id}/assign/student`"
                  href="#"
                  class="btn btn-danger font-weight-bolder font-size-sm"
                >
                  <i class="flaticon2-user"></i>
                  Siswa</router-link
                >
                &nbsp;
                <router-link
                  :to="`/kelas/${data.item._id}/assign/teacher`"
                  href="#"
                  class="btn btn-info font-weight-bolder font-size-sm"
                >
                  <i class="flaticon2-user"></i>
                  Guru</router-link
                >
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";

import {
  GET_CLASS_LIST,
  DELETE_CLASS,
} from "@/core/services/store/kelas.module";

export default {
  name: "KelasList",
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "class_code",
          label: "Kode Kelas",
          sortable: true,
        },
        {
          key: "class_name",
          label: "Nama Kelas",
          sortable: true,
        },
        {
          key: "subject",
          label: "Subject",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Kelas" }]);
    this.getData();
  },
  computed: {
    ...mapState({
      errors: (state) => state.user.errors,
    }),
    ...mapGetters(["currentClassList"]),
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return false;
    },
  },

  methods: {
    removeDuplicate(kelasArr) {
      const arr = kelasArr.map((item) => {
        return item.kelas.nama_kelas;
      });
      return arr.filter((item, pos, self) => {
        return self.indexOf(item) == pos;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = filteredItems.length;
      item.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      const params = new URLSearchParams();
      params.append("limit", this.perPage);
      params.append("page", this.currentPage);
      this.$store
        .dispatch(GET_CLASS_LIST, params.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentClassList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Kelas`,
            variant: "danger",
            solid: true,
          });
        });
    },
    setActiveTab(event, item) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      item.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    watch: {
      perPage: function () {
        this.getData();
      },
      currentPage: function () {
        this.getData();
      },
    },
    deleteItem(item) {
      console.log(item);
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus Kelas ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_CLASS, item._id)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus kelas`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Publish Ujian`,
                  variant: "danger",
                  solid: true,
                });
              });

            this.getData();
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
  },
};
</script>

<style scoped>
</style>
